'use client';

import ErrorBoundary from '@sbt-web/error-boundary';
import { Headline5 } from '@sbt-web/ui';
import classnames from 'classnames';
import React, { type FunctionComponent } from 'react';
import Markdown from 'react-markdown';
import { getTrackingInstance } from '../../helpers/PulseTracking';

import styles from './index.module.scss';

const clickMagazineTracking = () => {
  getTrackingInstance()?.queueEvent({
    type: 'Click',
    object: {
      '@type': 'UIElement',
      '@id': 'sdrn:subito:homepage:magazine:element:magazine-widget',
      elementType: 'Button',
      name: 'Click on Magazine Widget',
    },
  });
};

export type MagazineWidgetFeatures = {
  linkURL: string;
  image: string;
  imageAlt: string;
  tagColor: string;
  tag: string;
  title: string;
  body: string;
} | null;
type Props = {
  features: MagazineWidgetFeatures;
};
const MagazineWidget: FunctionComponent<Props> = ({ features }: Props) => {
  if (features) {
    return (
      <ErrorBoundary>
        <section className="vertical-block">
          <a
            id="banner-magazine"
            href={features.linkURL}
            className={styles['magazine']}
            onClick={() => {
              clickMagazineTracking();
            }}
          >
            <img
              src={`${process.env.NEXT_PUBLIC_ASSETS_BASE}/static/product-marketing/${features.image}`}
              alt={features.imageAlt}
              className={styles['image']}
              decoding="async"
              loading="lazy"
            />

            <div className={styles['text']}>
              <p
                className={classnames(styles['tag'])}
                style={
                  features.tagColor !== '' && features.tagColor !== undefined
                    ? { color: features.tagColor }
                    : undefined
                }
              >
                {features.tag}
              </p>
              <Headline5 element="h2" classes={[styles['title']]}>
                {features.title}
              </Headline5>
              <Markdown>{features.body}</Markdown>
            </div>
          </a>
        </section>
      </ErrorBoundary>
    );
  }
};

export default MagazineWidget;
