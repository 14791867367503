import { GeoEntry } from '@sbt-web/networking';

const areSameGeo = (
  previousValue: GeoEntry,
  value: GeoEntry,
  previousValueSearchNearRegions: boolean,
  valueSearchNearRegions: boolean
): boolean => {
  const includeNearbyRegionsMatch =
    previousValueSearchNearRegions === valueSearchNearRegions;

  if (includeNearbyRegionsMatch) {
    type GeoLevelKey = keyof GeoEntry;

    const geoLevelsMatch = (level: GeoLevelKey): boolean => {
      const prevLevelId = previousValue[level]?.id;
      const levelId = value[level]?.id;

      const noLevelId = prevLevelId == undefined && levelId == undefined;

      if (noLevelId) {
        return true;
      }

      const bothLevelIds = prevLevelId != undefined && levelId != undefined;

      if (!bothLevelIds) {
        return false;
      } else {
        return prevLevelId === levelId;
      }
    };

    const keys: GeoLevelKey[] = ['region', 'city', 'town', 'zone'];

    for (let index = 0; index < keys.length; index++) {
      const currentKey: GeoLevelKey = keys[index];

      if (!geoLevelsMatch(currentKey)) {
        return false;
      }
    }

    return true;
  } else {
    return false;
  }
};

export { areSameGeo };
