import React from 'react';
import styles from './index.module.scss';

const TOP_BANNER_ID = 'apn_top_tag';
const AdvTopBanner = () => {
  return (
    <div
      className={styles['wrapper']}
      style={{
        backgroundImage: `url('${process.env.NEXT_PUBLIC_ASSETS_BASE}/static/reclame/reclame-logo.webp')`,
      }}
    >
      <div id={TOP_BANNER_ID} className={styles['banner']} />
    </div>
  );
};

export { AdvTopBanner };
