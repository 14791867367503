import React from 'react';
import { AndromedaClient } from '@sbt-web/networking';
import { PromoteWidget } from './PromoteWidget';

const andromedaClient = new AndromedaClient(
  // I'm using '/hades' instead of config.HADES_PUBLIC_BASE (which is
  // configured with hades.subito.it) with the aim of make a request to the same
  // domain of the page, and avoid CORS.
  '/hades',
  'web',
  1000
);

export const PromoteWidgetWrapper = () => (
  <PromoteWidget
    reservedAreaBase={process.env.NEXT_PUBLIC_RESERVEDAREA_BASE}
    assetsBase={process.env.NEXT_PUBLIC_ASSETS_BASE}
    andromedaClient={andromedaClient}
  />
);
