import React from 'react';
import { SearchForm } from './Form';
import { Verticals } from './Verticals';

export const SearchBox = function () {
  return (
    <>
      <SearchForm />
      <Verticals />
    </>
  );
};
