import { getCategory } from '@sbt-web/tracking';
import type { ManageAdsItem } from '@sbt-web/networking';
import { urnTransformer } from '@sbt-web/utils';
import { getTrackingInstance } from '../../../../helpers/PulseTracking';

export const trackView = (): void => {
  getTrackingInstance()?.queueEvent({
    '@type': 'View',
    name: 'View Aumenta Visibilita Widget',
    object: {
      '@type': 'UIElement',
      '@id': 'sdrn:subito:homepage:manageads:element:more-visibility',
      label: 'View Aumenta Visibilita Widget',
    },
  });
};
export const trackItemClick = (itemIndex: number, ad: ManageAdsItem): void => {
  const ids = urnTransformer(ad.urn);
  const adId = ids?.adId ?? '';
  getTrackingInstance()?.queueEvent({
    '@type': 'Click',
    name: 'Click on Aumenta Visibilita Item',
    object: {
      '@type': 'UIElement',
      '@id': `sdrn:subito:homepage:manageads:element:more-visibility-item-${itemIndex}`,
    },
    target: {
      '@id': `sdrn:subito:classified:${adId}`,
      '@type': 'ClassifiedAd',
      adId,
      category: getCategory(ad.category),
    },
  });
};
