import type { ENV } from '@sbt-web/utils';

let intervalId: undefined | number;

const reloadAdvWithDefaultUnits = (mediaQueryList: MediaQueryList) => {
  window.subitoAdv.gpt.useDefaultAdUnits({
    pageType: 'homepage',
    isDesktop: mediaQueryList.matches,
    env: process.env.NEXT_PUBLIC_INTERNAL_ENVIRONMENT as ENV,
  });
  window.subitoAdv.gpt.runAdv();

  //Refresh every 30 seconds:
  if (!intervalId) {
    intervalId = window.setInterval(
      window.subitoAdv.gpt.reloadRefreshableAdUnits.bind(window.subitoAdv.gpt),
      30000
    );
  }
};

export const runAdvCb = function (): void {
  const mediaQueryList: MediaQueryList =
    window.matchMedia('(min-width: 980px)');
  reloadAdvWithDefaultUnits(mediaQueryList);
};

export const runAdv = function (): void {
  window.subitoAdvQueue.push(async () => {
    await runAdvCb();
  });
};
