'use client';

import ErrorBoundary from '@sbt-web/error-boundary';
import { ButtonLink, DeviceWidth, Headline5 } from '@sbt-web/ui';
import classnames from 'classnames';
import React, { type FunctionComponent } from 'react';
import Markdown from 'react-markdown';
import { clickAdBoxTracking } from '../../helpers/PulseTracking';

import CSS from './index.module.scss';

export type SellWidgetFeatures = {
  coverDesktopImageURL: string;
  coverMobileImageURL: string;
  buttonLinkURL: string;
  buttonLinkText: string;
  title: string;
  body: string;
} | null;
type Props = {
  features: SellWidgetFeatures;
};
const SellWidget: FunctionComponent<Props> = ({ features }: Props) => {
  if (features) {
    return (
      <ErrorBoundary>
        <section className={classnames(CSS['insert-box'], 'vertical-block')}>
          <picture className={CSS['background-photo']}>
            <source
              srcSet={`${process.env.NEXT_PUBLIC_ASSETS_BASE}/static/product-marketing/${features.coverDesktopImageURL}`}
              media={`(min-width: ${DeviceWidth.tablet.min})`}
            />
            <img
              src={`${process.env.NEXT_PUBLIC_ASSETS_BASE}/static/product-marketing/${features.coverMobileImageURL}`}
              alt=""
              loading="lazy"
              decoding="async"
            />
          </picture>

          <div className={CSS['insert-box-inner']}>
            <Headline5 element="h2">{features.title}</Headline5>
            <Markdown>{features.body}</Markdown>
          </div>

          <ButtonLink
            design="solid-lite"
            href={features.buttonLinkURL}
            classes={[CSS['insert-box-buttonlink']]}
            onClick={() => clickAdBoxTracking()}
            iconLeft={
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M8 5a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3zm0-2h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5V8a5 5 0 0 1 5-5m5 8h3a1 1 0 0 1 0 2h-3v3a1 1 0 0 1-2 0v-3H8a1 1 0 0 1 0-2h3V8a1 1 0 0 1 2 0z"
                />
              </svg>
            }
          >
            {features.buttonLinkText}
          </ButtonLink>
        </section>
      </ErrorBoundary>
    );
  }
};

export default SellWidget;
