import React from 'react';
import { Verticals as VerticalsObject } from '../categories';
import { VerticalBox } from './VerticalsBox';
import CSSModule from './index.module.scss';

export const Verticals = () => {
  return (
    <section className={CSSModule['discover-subito']}>
      {Object.values(VerticalsObject).map((value) => {
        return (
          <div className={CSSModule['vertical-wrapper']} key={value.key}>
            <VerticalBox vertical={value} />
          </div>
        );
      })}
    </section>
  );
};
