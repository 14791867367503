import { getPublicUser } from '@sbt-web/auth';
import { Pulse } from '@sbt-web/tracking';

let tracking: Pulse | undefined = undefined;

/**
 * The entrypoint for creating the Tracker instances.
 */
const getTrackingInstance = function (): Pulse | undefined {
  if (typeof window === 'undefined') {
    // If not on the client, we cannot instantiate Pulse.
    return undefined;
  } else if (tracking !== undefined) {
    // If we have a Pulse instance, return it.
    return tracking;
  } else {
    // If there is no instance, create a new Pulse instance.
    tracking = new Pulse();

    // Set the Env
    tracking.setEnv(process.env.NEXT_PUBLIC_INTERNAL_ENVIRONMENT);

    // Set the Actor when the user is logged in
    const userDetails = getPublicUser();
    if (userDetails && userDetails.id) {
      tracking.setActor(userDetails.id);
    }
    return tracking;
  }
};

const viewTracking = async function (): Promise<void> {
  let adBlock = 'inactive';
  try {
    await fetch(
      `${process.env.NEXT_PUBLIC_ASSETS_BASE}/v2/libraries/adblock_detector.js`,
      {
        method: 'GET',
      }
    );
  } catch (error) {
    adBlock = 'active';
  }

  const pulseVal = {
    type: 'View',
    object: {
      type: 'Page',
      id: 'homepage',
    },
    page: {
      '@type': 'Page',
      '@id': 'sdrn:subito:page:homepage',
      pagetype: 'homepage',
    },
    adBlock,
  };
  getTrackingInstance()?.queueEvent(pulseVal);
};

const viewSurveyTracking = function (): void {
  const pulseVal = {
    type: 'View',
    object: {
      '@type': 'UIElement',
      '@id': 'sdrn:subito:homepage:modal:element:trx-csat-survey-invite',
      label: 'Transaction csat survey invite',
    },
  };
  getTrackingInstance()?.queueEvent(pulseVal);
};

const clickNewAdTrackingFromMenu = function (): void {
  const pulseVal = {
    type: 'Click',
    object: {
      '@type': 'UIElement',
      '@id': 'sdrn:subito:homepage:adinsertion:element:ad-in-button-top',
      elementType: 'Button',
      name: 'Click on Ad Insertion Button',
    },
  };
  getTrackingInstance()?.queueEvent(pulseVal);
};

const clickAdBoxTracking = function (): void {
  const pulseVal = {
    type: 'Click',
    object: {
      '@type': 'UIElement',
      '@id': 'sdrn:subito:homepage:adinsertion:element:ad-in-button-box',
      elementType: 'Button',
      name: 'Click on Ad Insertion Button',
    },
  };
  getTrackingInstance()?.queueEvent(pulseVal);
};

const clickDownloadAppTracking = function (): void {
  const pulseVal = {
    type: 'Click',
    object: {
      '@type': 'UIElement',
      '@id': 'sdrn:subito:homepage:content:element:box-download-app',
      elementType: 'Button',
      name: 'Click on Download App',
    },
  };
  getTrackingInstance()?.queueEvent(pulseVal);
};

const clickSearchBarTracking = function (): void {
  const pulseVal = {
    type: 'Click',
    object: {
      '@type': 'UIElement',
      '@id': 'sdrn:subito:homepage:search:element:search-bar-category',
      elementType: 'Button',
      name: 'Click on Search Bar',
    },
  };
  getTrackingInstance()?.queueEvent(pulseVal);
};

const clickGeoSearchTracking = function (
  e?: React.MouseEvent<HTMLDivElement, MouseEvent>
): void {
  if (e && !(e.target instanceof HTMLInputElement)) {
    return;
  }

  const pulseVal = {
    type: 'Click',
    object: {
      '@type': 'UIElement',
      '@id': 'sdrn:subito:homepage:search:element:search-bar-locality',
      elementType: 'Button',
      name: 'Click on Search Bar',
    },
  };
  getTrackingInstance()?.queueEvent(pulseVal);
};

const clickHeaderTracking = function (href: string | undefined): void {
  const url = href !== undefined ? href.replace(/(^\w+:|^)\/\//, '') : '';
  const pulseVal = {
    type: 'Click',
    object: {
      '@type': 'UIElement',
      '@id': `sdrn:subito:homepage:header:element:${url}`,
      elementType: 'Button',
      name: `Click on Header - ${url}`,
    },
  };
  getTrackingInstance()?.queueEvent(pulseVal);
};

const clickFooterTracking = function (href: string | undefined): void {
  const url = href !== undefined ? href.replace(/(^\w+:|^)\/\//, '') : '';
  const pulseVal = {
    type: 'Click',
    object: {
      '@type': 'UIElement',
      '@id': `sdrn:subito:homepage:footer:element:${url}`,
      elementType: 'Button',
      name: `Click on Footer - ${url}`,
    },
  };
  getTrackingInstance()?.queueEvent(pulseVal);
};

const clickVerticalTracking = function (
  vertical: 'motori' | 'market' | 'immobili' | 'lavoro'
): void {
  const pulseVal = {
    type: 'Click',
    object: {
      '@type': 'UIElement',
      '@id': `sdrn:subito:homepage:search:element:${vertical}`,
      elementType: 'Button',
      name: `Click on Vertical ${vertical}`,
    },
  };
  getTrackingInstance()?.queueEvent(pulseVal);
};

export {
  clickAdBoxTracking,
  clickDownloadAppTracking,
  clickFooterTracking,
  clickGeoSearchTracking,
  clickHeaderTracking,
  clickNewAdTrackingFromMenu,
  clickSearchBarTracking,
  clickVerticalTracking,
  getTrackingInstance,
  viewSurveyTracking,
  viewTracking,
};
