import React from 'react';
import CSS from './index.module.scss';

export const AdvGoogleBox = function () {
  return (
    <section className="vertical-block">
      <div className={CSS['box_homepage']} id="box_homepage">
        <div
          className={CSS['box_homepage_inner']}
          id="dfp_box_homepage_desktop"
        ></div>
      </div>
    </section>
  );
};
