import React from 'react';
import classnames from 'classnames';
import type { Vertical } from '../../categories';
import CSS from './index.module.scss';

export const LeftModal = function ({ vertical }: { vertical?: Vertical }) {
  return (
    <div
      className={classnames(
        CSS['sx-inner'],
        vertical ? CSS[vertical.key] : null
      )}
    >
      <img
        src={`/ekhaya/subito-${vertical?.key}.svg`}
        alt={`Subito ${vertical?.key}`}
        width={168}
      />
    </div>
  );
};
