import React from 'react';
import classnames from 'classnames';
import CSSModule from './index.module.scss';

export interface Category {
  key: string;
  label: string;
  linksGroups: LinksGroup[];
}

interface Props {
  cat: Category;
}

interface LinkProps {
  link: Link;
}

export interface Link {
  isTitle?: boolean;
  url: string;
  label: string;
}

export interface LinksGroup {
  links: Link[];
}

interface LinksGroupProps {
  group: LinksGroup;
}

export const CategoryBox = ({ cat }: Props) => {
  return (
    <div
      className={classnames(
        CSSModule['internal-links-links'],
        CSSModule['macro-category']
      )}
    >
      <div className={CSSModule['links-group']}>
        <h5 className={classnames(CSSModule[`cat-${cat.key}`])}>{cat.label}</h5>
        {cat.linksGroups.map((value, i) => {
          return <LinksGroup group={value} key={`${i}-${cat.key}`} />;
        })}
      </div>
    </div>
  );
};

export const LinksGroup = ({ group }: LinksGroupProps) => {
  return (
    <div className={CSSModule['links-group']}>
      {group.links
        .map<React.ReactNode>((value, i) => (
          <Link link={value} key={`${i}-${value.label}`} />
        ))
        .reduce((prev, curr, i) => {
          if (i > 0 && !group.links[i - 1].isTitle) {
            return [prev, ' - ', curr];
          }
          return [prev, curr];
        })}
    </div>
  );
};

export const Link = ({ link }: LinkProps) => {
  return (
    <a
      className={classnames({
        [CSSModule['internal-links-brand']]: link.isTitle,
      })}
      href={link.url}
    >
      {link.label}
    </a>
  );
};
