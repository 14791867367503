import React from 'react';
import { Modal } from '../../Modal';
import { LeftModal } from './LeftModal';
import { RightModal } from './RightModal';
import type { Vertical } from '../../categories';
import CSS from './index.module.scss';
import classnames from 'classnames';

export const VerticalModal = function ({
  vertical,
  onClickClose,
  show,
}: {
  vertical: Vertical;
  onClickClose: () => void;
  show: boolean;
}) {
  return (
    <Modal
      useCloseArrow={false}
      modalRight={<RightModal vertical={vertical} />}
      modalLeft={<LeftModal vertical={vertical} />}
      show={show}
      onClose={onClickClose}
      className={classnames(CSS['modal'], vertical ? CSS[vertical.key] : null)}
    />
  );
};
