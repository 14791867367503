'use client';

import GeoSearch, { GeoSearchSelection } from '@sbt-web/geo-search';
import React from 'react';
import {
  clickGeoSearchTracking,
  getTrackingInstance,
} from '../../../../helpers/PulseTracking';
import type { Vertical } from '../../categories';
import { GeoLocation, translateParamsToUrl } from '../../navigateToListing';
import type { CategoryInfo } from '../../types';
import { CategoriesList } from './CategoriesList';

import CSS from './index.module.scss';

const ANIMATION_ENDED = 30;
export const RightModal = function ({ vertical }: { vertical: Vertical }) {
  const [query, setQuery] = React.useState<string>();
  const [category, setCategory] = React.useState<CategoryInfo | undefined>({
    key: vertical.default.id,
    label: vertical.default.label,
  });
  const [location, setLocation] = React.useState<GeoLocation | undefined>();
  const firstInput = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    window.setTimeout(() => {
      firstInput.current?.focus();
    }, ANIMATION_ENDED);
  }, []);

  const startingValue = React.useMemo<GeoSearchSelection | undefined>(() => {
    if (!location) {
      return;
    } else {
      return {
        entry: location.geo,
        searchNearRegions: location?.includeNearbyRegions ?? false,
        responseFrom: 'geogos' as const,
      };
    }
  }, [location]);

  return (
    <div className={CSS['modal-dx-inner']}>
      <form
        name="modal-search-form"
        id="modal-search-form"
        onSubmit={(e) => {
          e.preventDefault();
          translateParamsToUrl(query, category, location);
        }}
      >
        <div className={CSS['form-group-01']}>
          <label
            className={CSS['input-label']}
            htmlFor={`modal-keyword-field-${vertical.key}`}
          >
            Cosa cerchi?
          </label>
          <div className={CSS['input']}>
            <img
              src={`/ekhaya/icons/cactus/lens.svg`}
              width="38"
              height="38"
              alt=""
              className={CSS['input-icon']}
            />

            <input
              type="text"
              ref={firstInput}
              name="q"
              id={`modal-keyword-field-${vertical.key}`}
              placeholder="Vespa, Cucina, Bilocale"
              onChange={(e) => setQuery(e.target.value)}
              autoComplete="off"
            />
          </div>

          <div>
            <CategoriesList
              vertical={vertical}
              onChangeCategory={setCategory}
            />
          </div>

          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
          <div onClick={(e) => clickGeoSearchTracking(e)}>
            <label
              className={CSS['input-label']}
              htmlFor={`modal-location-field-${vertical.key}`}
            >
              Dove?
            </label>
            <GeoSearch
              onDialogOpen={() => clickGeoSearchTracking()}
              assetsBase={process.env.NEXT_PUBLIC_ASSETS_BASE}
              hadesBasePath="/hades"
              name="geo-search-field"
              labelId={`modal-location-field-${vertical.key}`}
              onSelect={(item) => {
                if (item && item.responseFrom === 'geogos') {
                  const { entry, searchNearRegions } = item;
                  setLocation({
                    geo: entry,
                    includeNearbyRegions: searchNearRegions,
                  });
                }
              }}
              value={startingValue}
              hereApiKey=""
              pulseInstance={getTrackingInstance()}
            />
          </div>
          <button className={CSS['submit-button']} type="submit">
            Cerca
          </button>
        </div>
      </form>
    </div>
  );
};
