'use client';

import ErrorBoundary from '@sbt-web/error-boundary';
import { BodyText, Subhead } from '@sbt-web/ui';
import React from 'react';
import Markdown from 'react-markdown';
import ownClasses from './index.module.scss';

interface CardProps {
  image: string;
  title: string;
  body?: string;
  url: string;
  linkText: string;
}

const Card = ({ url, title, body, linkText, image }: CardProps) => {
  return (
    <a href={url} className={ownClasses.card}>
      <img
        src={`${process.env.NEXT_PUBLIC_ASSETS_BASE}/static/product-marketing/${image}`}
        alt=""
        width={265}
        className={ownClasses.image}
        loading="lazy"
        decoding="async"
      />
      <Subhead classes={[ownClasses.title]}>{title}</Subhead>

      {body !== undefined && body !== '' ? <Markdown>{body}</Markdown> : null}

      <BodyText size="small" classes={[ownClasses.text]}>
        {linkText}
      </BodyText>
    </a>
  );
};

export type SafetyTipsWidgetFeatures = {
  title: string;
  card1Image: string;
  card1Title: string;
  card1Body: string;
  card1LinkText: string;
  card1Link: string;
  card2Image: string;
  card2Title: string;
  card2Body: string;
  card2LinkText: string;
  card2Link: string;
  card3Image: string;
  card3Title: string;
  card3Body: string;
  card3LinkText: string;
  card3Link: string;
} | null;
type Props = { features: SafetyTipsWidgetFeatures };
const SafetyTips = ({ features }: Props) => {
  if (features) {
    return (
      <ErrorBoundary>
        <section className={ownClasses.safety_tips_box}>
          <h2 className="headline-5">{features.title}</h2>

          <div className={ownClasses.cards_box}>
            <Card
              image={features.card1Image}
              title={features.card1Title}
              body={features.card1Body}
              linkText={features.card1LinkText}
              url={features.card1Link}
            />
            <Card
              image={features.card2Image}
              title={features.card2Title}
              body={features.card2Body}
              linkText={features.card2LinkText}
              url={features.card2Link}
            />
            <Card
              image={features.card3Image}
              title={features.card3Title}
              body={features.card3Body}
              linkText={features.card3LinkText}
              url={features.card3Link}
            />
          </div>
        </section>
      </ErrorBoundary>
    );
  }
};

export default SafetyTips;
