import React, { useContext } from 'react';
import classnames from 'classnames';
import { clickVerticalTracking } from '../../../../helpers/PulseTracking';
import { VerticalModal } from '../VerticalModal';
import CSSModule from './index.module.scss';
import type { Vertical } from '../../categories';
import { OptimizelySubitoContext } from '@sbt-web/houston-wrapper';

interface Props {
  vertical: Vertical;
}

export const VerticalBox = ({ vertical }: Props) => {
  const showVertical = function (vertical: Vertical) {
    setActiveVertical(vertical);
    setShowModal(true);
  };
  const closeVertical = function () {
    setActiveVertical(undefined);
    setShowModal(false);
  };

  const [activeVertical, setActiveVertical] = React.useState<Vertical>();
  const [showModal, setShowModal] = React.useState(false);
  const { optimizely } = useContext(OptimizelySubitoContext);

  return (
    <>
      {activeVertical ? (
        <VerticalModal
          vertical={activeVertical}
          onClickClose={closeVertical}
          show={showModal}
        />
      ) : null}
      <button
        className={classnames(
          CSSModule['category-box'],
          CSSModule[`category-box-${vertical.key}`]
        )}
        data-vertical={vertical.key}
        id={`vertical-${vertical.key}`}
        onClick={() => {
          showVertical(vertical);
          clickVerticalTracking(vertical.key);
          optimizely?.track('click_vertical_hp_polaris');
        }}
      >
        <div className={CSSModule['category-box__icon-wrapper']}>
          <img
            src={`/ekhaya/vertical-${vertical.key}.svg`}
            alt={''}
            className={CSSModule['category-box__icon']}
          />
        </div>
        <span className={classnames(CSSModule['category-box__label'], 'bold')}>
          {vertical.label}
        </span>
      </button>
    </>
  );
};
