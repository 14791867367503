import React from 'react';
import classnames from 'classnames';
import CSS from './index.module.scss';
import { clickDownloadAppTracking } from '../../helpers/PulseTracking';

export const AppWidget = function () {
  return (
    <div className={CSS['download-box-wrapper']}>
      <section className={classnames('vertical-block', CSS['download-box'])}>
        <h2 className={classnames('title', CSS['title-widget'], 'bold')}>
          Scarica l’App ufficiale di Subito.
        </h2>
        <p className={CSS['description-widget']}>
          Cerca tra migliaia di annunci e inserisci i tuoi, ovunque tu sia.
        </p>
        <div className={CSS['row']}>
          <div className={CSS['icons-store']}>
            <a
              id="download-android-app"
              href="https://play.google.com/store/apps/details?id=it.subito&referrer=utm_source%3Dsite%26utm_medium%3Dfooter"
              onClick={clickDownloadAppTracking}
            >
              <img
                src="/ekhaya/google-play-badge.svg"
                className="lazy"
                alt="Scarica l'App per Android su Google Play"
              />
            </a>
          </div>
          <div className={CSS['icons-store']}>
            <a
              href="https://apps.apple.com/it/app/subito-it/id450775137"
              id="download-ios-app"
              onClick={clickDownloadAppTracking}
            >
              <img
                src="/ekhaya/app-store-badge.svg"
                className="lazy"
                alt="Scarica l'App per iOS su App Store"
              />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
