// SEO
export const SITE_NAME = 'Subito.it';
export const SITE_TITLE = 'Subito.it';
export const SITE_DESCRIPTION =
  'Annunci gratuiti vendita o affitto case, annunci auto e moto usate, offerte lavoro e molto altro. Inserisci gratis il tuo annuncio su Subito';
export const SITE_IMAGE = '';

// Network
export const HADES_PATH = '/hades';
export const WEB_API_CHANNEL = 'web';
export const MOBILE_API_CHANNEL = 'msite';

// Google Tag Manager
export const GTM_URL = 'https://www.googletagmanager.com/gtm.js?id=GTM-TWPJW6H';
export const GTM_STAGE_SUFFIX =
  '&gtm_auth=KOPoJMwiqaNuTvoLKOs7hA&gtm_preview=env-5';

// Cookie and auth
export const LABEL_REQUESTID = 'X-Subito-Req-Id';
