import { type ManageAdsItem } from '@sbt-web/networking';
import classnames from 'classnames';
import React from 'react';

import classes from './index.module.scss';

type SupportedImageRule =
  | 'card-mobile-new-small'
  | 'ad-promote-desktop-carousel'
  | 'ad-promote-desktop-unique';

interface Props {
  item: ManageAdsItem;
  className?: string;
  assetsBase: string;
  imageRule: SupportedImageRule;
}

const CameraIcon: React.FunctionComponent<{
  count: number;
  assetsBase: string;
}> = ({ count, assetsBase }) => (
  <img
    // The alt is plural because the icon isn't shown if there's only one image.
    alt={`Questo annuncio ha ${count} immagini`}
    src={`${assetsBase}/static/icons/cactus/camera.svg`}
    className={classes['camera-icon']}
    width={16}
    height={16}
    loading="lazy"
    decoding="async"
  />
);

const placeholderSize = 40;
const IMAGE_SIZES: Record<
  SupportedImageRule,
  { width: number; height: number }
> = {
  'card-mobile-new-small': {
    width: 108,
    height: 82,
  },
  'ad-promote-desktop-carousel': {
    width: 108,
    height: 126,
  },
  'ad-promote-desktop-unique': {
    width: 202,
    height: 126,
  },
};

export const PromoteCardImage: React.FC<Props> = ({
  item,
  assetsBase = 'https://assets.subito.it',
  className,
  imageRule,
}) => {
  const baseSource = item.images?.[0]?.cdnBaseUrl;
  const imageSource = {
    ...IMAGE_SIZES[imageRule],
    src: `${baseSource}?rule=${imageRule}-1x-auto`,
    srcset: `${baseSource}?rule=${imageRule}-1x-auto 1x, ${baseSource}?rule=${imageRule}-2x-auto 2x, ${baseSource}?rule=${imageRule}-3x-auto 3x`,
  };

  return (
    <>
      <div
        className={classnames(
          className,
          classes.container,
          classes[`container--${imageRule}`]
        )}
      >
        <figure className={classes['photo-container']}>
          {baseSource ? (
            <img
              src={imageSource.src}
              alt={item.subject}
              // When the connection is slow or requests data saving, use only the 1x
              srcSet={imageSource.srcset}
              width={imageSource.width}
              height={imageSource.height}
              className={classes.photo}
              loading={'eager'}
              decoding="async"
            />
          ) : (
            <img
              src={`${assetsBase}/static/icons/categories/${item.category.id}.svg`}
              alt={item.subject}
              width={placeholderSize}
              height={placeholderSize}
              className={classes.placeholder}
              loading="lazy"
              decoding="async"
            />
          )}
        </figure>
        {item.images.length > 1 && (
          <div className={classes.count}>
            <CameraIcon count={item.images.length} assetsBase={assetsBase} />
            <span aria-hidden="true">{item.images.length}</span>
          </div>
        )}
      </div>
    </>
  );
};
