'use client';

import ErrorBoundary from '@sbt-web/error-boundary';
import { ButtonLink, DeviceWidth, Headline5 } from '@sbt-web/ui';
import classnames from 'classnames';
import React, { type FunctionComponent } from 'react';
import Markdown from 'react-markdown';

import ownClasses from './index.module.scss';

export type TuttoSubitoWidgetFeatures = {
  title: string;
  subtitle: string;
  sellerImageDesktopURL: string;
  sellerImageMobileURL: string;
  sellerLink: string;
  sellerLinkText: string;
  buyerImageDesktopURL: string;
  buyerImageMobileURL: string;
  buyerLink: string;
  buyerLinkText: string;
} | null;
type Props = {
  features: TuttoSubitoWidgetFeatures;
};
const TuttoSubito: FunctionComponent<Props> = ({ features }: Props) => {
  if (features) {
    return (
      <ErrorBoundary>
        <section
          className={classnames(ownClasses['tutto-subito'], 'vertical-block')}
        >
          <div className={ownClasses['tutto-subito__info']}>
            <Headline5 element="h2">{features.title}</Headline5>
            <Markdown>{features.subtitle}</Markdown>
          </div>
          <div className={ownClasses['tutto-subito__actions']}>
            <div className={ownClasses['tutto-subito__content-container']}>
              <picture>
                <source
                  srcSet={`${process.env.NEXT_PUBLIC_ASSETS_BASE}/static/product-marketing/${features.sellerImageDesktopURL}`}
                  media={`(min-width: ${DeviceWidth.desktop.min})`}
                />
                <img
                  src={`${process.env.NEXT_PUBLIC_ASSETS_BASE}/static/product-marketing/${features.sellerImageMobileURL}`}
                  alt=""
                  width={300}
                  height={216}
                  loading="eager"
                  decoding="async"
                />
              </picture>
              <ButtonLink
                design="solid"
                href={features.sellerLink}
                classes={[ownClasses['tutto-subito__button']]}
              >
                {features.sellerLinkText}
              </ButtonLink>
            </div>
            <div className={ownClasses['tutto-subito__content-container']}>
              <picture>
                <source
                  srcSet={`${process.env.NEXT_PUBLIC_ASSETS_BASE}/static/product-marketing/${features.buyerImageDesktopURL}`}
                  media={`(min-width: ${DeviceWidth.desktop.min})`}
                />
                <img
                  src={`${process.env.NEXT_PUBLIC_ASSETS_BASE}/static/product-marketing/${features.buyerImageMobileURL}`}
                  alt=""
                  width={300}
                  height={216}
                />
              </picture>

              <ButtonLink
                design="solid"
                href={features.buyerLink}
                classes={[ownClasses['tutto-subito__button']]}
              >
                {features.buyerLinkText}
              </ButtonLink>
            </div>
          </div>
        </section>
      </ErrorBoundary>
    );
  }
};

export default TuttoSubito;
