import { CategoryId, CategoryStore } from '@sbt-web/networking';

const motori = CategoryStore.macroCategories.find(
  (c) => c.id === CategoryId.Motori
);
const Elettronica = CategoryStore.macroCategories.find(
  (c) => c.id === CategoryId.Elettronica
);
const CasaPersona = CategoryStore.macroCategories.find(
  (c) => c.id === CategoryId.CasaPersona
);
const SportsHobby = CategoryStore.macroCategories.find(
  (c) => c.id === CategoryId.SportsHobby
);
const Immobili = CategoryStore.macroCategories.find(
  (c) => c.id === CategoryId.Immobili
);
const Lavoro = CategoryStore.macroCategories.find(
  (c) => c.id === CategoryId.LavoroServizi
);

export interface CategoryMinimal {
  id: CategoryId;
  label: string;
  categories?: Array<Omit<CategoryMinimal, 'categories'>>;
}

export interface Vertical {
  key: 'motori' | 'market' | 'immobili' | 'lavoro';
  label: string;
  categories: Array<CategoryMinimal>;
  default: CategoryMinimal;
}

const CategoriesImmobili: Array<CategoryMinimal> = [
  ...(Immobili?.categories ?? []),
];
const ImmobiliDefault = { label: 'Tutto immobili', id: CategoryId.Immobili };
CategoriesImmobili.push(ImmobiliDefault);

const CategoriesMotori: Array<CategoryMinimal> = [
  ...(motori?.categories ?? []),
];
const MotoriDefault = { label: 'Tutto motori', id: CategoryId.Motori };
CategoriesMotori.push(MotoriDefault);

const CategoriesLavoro: Array<CategoryMinimal> = [
  ...(Lavoro?.categories ?? []),
];
const LavoroDefault = { label: 'Tutto lavoro', id: CategoryId.LavoroServizi };
CategoriesLavoro.push(LavoroDefault);

const MarketDefault = { label: 'Tutte le categorie', id: CategoryId.Tutte };
const CategoriesMarket: Array<CategoryMinimal> = [
  MarketDefault,
  {
    label: 'Elettronica',
    id: CategoryId.Elettronica,
    categories: Elettronica?.categories ?? [],
  },
  {
    label: 'Per la casa e la persona',
    id: CategoryId.CasaPersona,
    categories: CasaPersona?.categories ?? [],
  },
  {
    label: 'Sports e hobby',
    id: CategoryId.SportsHobby,
    categories: SportsHobby?.categories ?? [],
  },
  { label: 'Altri', id: CategoryId.AltriAnnunci, categories: [] },
];

type key = 'Motori' | 'Lavoro' | 'Immobili' | 'Market';
export const Verticals: { [verticalKey in key]: Vertical } = {
  Motori: {
    categories: CategoriesMotori,
    key: 'motori',
    label: 'Motori',
    default: MotoriDefault,
  },
  Market: {
    categories: CategoriesMarket,
    key: 'market',
    label: 'Market',
    default: MarketDefault,
  },
  Immobili: {
    categories: CategoriesImmobili,
    key: 'immobili',
    label: 'Immobili',
    default: ImmobiliDefault,
  },
  Lavoro: {
    categories: CategoriesLavoro,
    key: 'lavoro',
    label: 'Lavoro',
    default: LavoroDefault,
  },
};
